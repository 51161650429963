import DropListAutocomplete from "../../../../components/DropListAutocomplete"
import InputText from "../../../../components/FeedbackElements/InputText"
import MultiChoice from "../../../../components/FeedbackElements/MultiChoice"
import RadioFeedback from "../../../../components/FeedbackElements/RadioFeedback"
import SingleChoice from "../../../../components/FeedbackElements/SingleChoice"
import TextArea from "../../../../components/FeedbackElements/TextArea"

const SubmissionPreview = ({ config, submission }) => {
  const user_submissions = submission.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.id]: { ...curr },
    }
  }, {})
  return (
    <div className="w-full p-2 pb-8 mx-auto bg-white border-b-4 border-sky-800">
      <div className="space-y-2 pb-4 mb-4 border-b border-gray-200">
        {config.title && (
          <h2 className="h2 tracking-tighter">{config.title}</h2>
        )}
        {config.configription && (
          <p className="text-base text-gray-600">{config.configription}</p>
        )}
      </div>

      <div className="questions space-y-4">
        {config.schema.map((c, index) => (
          <div className="flex flex-col">
            <div className="flex items-start gap-4" key={c.id}>
              <span className="h-6 w-6 bg-gray-700 text-white text-sm font-semibold flex items-center justify-center rounded-full">
                {index + 1}
              </span>

              {c.type === "singlechoice" && (
                <SingleChoice disabled config={c} />
              )}
              {c.type === "shorttext" && <InputText disabled config={c} />}
              {c.type === "longtext" && <TextArea disabled config={c} />}
              {c.type === "multichoice" && <MultiChoice disabled config={c} />}
              {c.type === "dropdown" && (
                <div className="flex flex-col gap-y-2">
                  {config.heading && (
                    <h5 className="h5 font-semibold">{config.heading}</h5>
                  )}
                  <label className="text-sm text-gray-600">{c.label}</label>
                  <DropListAutocomplete disabled data={c.options} />
                </div>
              )}
              {c.type === "reaction" && (
                <div className="flex flex-col gap-y-2">
                  {config.heading && (
                    <h5 className="h5 font-semibold">{config.heading}</h5>
                  )}
                  <label className="text-sm text-gray-600">{c.label}</label>
                  <RadioFeedback disabled data={c.options} />
                </div>
              )}
            </div>

            <div className="self-end">
              <span className="bg-sky-600 p-2 font-bold text-white">Answer</span>
              <span className="bg-gray-100 p-2 ring-1 ring-gray-300">{user_submissions[c.id].answer}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SubmissionPreview

import { useState, useEffect } from "react"
import CodeMirror, { basicSetup } from "@uiw/react-codemirror"
import { json } from "@codemirror/lang-json"
import { dracula } from "@uiw/codemirror-theme-dracula"
import { EditorView, keymap } from "@codemirror/view"
import { defaultKeymap } from "@codemirror/commands"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { ClipboardCopyIcon, DownloadIcon } from "@heroicons/react/outline"
import { toast } from "react-toastify"

const extensions = [keymap.of(defaultKeymap), json(), EditorView.lineWrapping]

const JSONSchema = ({ desc, setDesc, config, setConfig }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return

    toast.success("Schema copied!")

    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [copied])

  function handleDownload() {
    const a = document.createElement("a")
    const data = JSON.stringify(
      {
        title: desc.title,
        description: desc.description,
        schema: config,
      },
      null,
      2
    )
    const filename = `ACADAMETRICS_(${new Date().toISOString()}).json`

    const file = new Blob([data], { type: "application/json" })

    a.download = filename
    a.href = URL.createObjectURL(file)
    a.click()
  }

  return (
    <div>
      <CodeMirror
        value={JSON.stringify(
          {
            title: desc.title,
            description: desc.description,
            schema: config,
          },
          null,
          5
        )}
        height="100vh"
        suppressContentEditableWarning
        basicSetup={basicSetup}
        onChange={(value) => {
          const code = JSON.parse(value)

          setDesc({
            title: code.title,
            description: code.description,
          })

          setConfig(code.schema)
        }}
        theme={dracula}
        extensions={extensions}
        className="text-sm"
      />

      <div className="fixed bottom-2 right-12 flex items-center gap-2">
        <CopyToClipboard
          text={JSON.stringify(
            {
              title: desc.title,
              description: desc.description,
              schema: config,
            },
            null,
            5
          )}
          onCopy={() => setCopied(true)}
        >
          <button className="flex items-center gap-1 p-2">
            <ClipboardCopyIcon className="h-5 text-sky-400" />{" "}
            <small className="text-white font-semibold">Copy</small>
          </button>
        </CopyToClipboard>

        <button
          onClick={() => handleDownload()}
          className="flex items-center gap-1 p-2"
        >
          <DownloadIcon className="h-5 text-sky-400" />{" "}
          <small className="text-white font-semibold">Download</small>
        </button>
      </div>
    </div>
  )
}

export default JSONSchema
